import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import SignupForm from "./pages/signup";
import Resetpassword from "./pages/forgetPassword";
import Detail from "./pages/detail";
import UserForm from "./pages/Userform";
import Payout from "./pages/Payout";
import Payin from "./pages/payin";
import Settlement from "./pages/settlement";
import AddBank from "./pages/addBank";
import Account from "./pages/account";
import PaymentLink from "./pages/paymentLinks";
import PaymentApi from "./pages/paymentApi";
import SetUpi from "./pages/setUpi";
import Error from "./pages/error";
import MerchantDetail from "./pages/merchantDetail";
import ProtectedRoute from "./protectedRoute";
import Download from "./pages/download";

function External() {
  window.location.href =
    "https://documenter.getpostman.com/view/19668006/2s8YCbovL6";
  return null;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/signup" element={<SignupForm />}></Route>
      <Route path="/" element={<Login />}></Route>
      <Route path="*" element={<Error />}></Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/forgetpassword" element={<Resetpassword />}></Route>
        <Route path="/detail" element={<Detail />}></Route>
        <Route path="/userform" element={<UserForm />}></Route>
        <Route path="/table" element={<Payout />}></Route>
        <Route path="/payin" element={<Payin />}></Route>
        <Route path="/settlement" element={<Settlement />}></Route>
        <Route path="/addbank" element={<AddBank />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/paylink" element={<PaymentLink />}></Route>
        <Route path="/payapi" element={<PaymentApi />}></Route>
        <Route path="/payupi" element={<SetUpi />}></Route>
        <Route path="/merchantDetail" element={<MerchantDetail />}></Route>
        <Route path="/download" element={<Download />}></Route>
        <Route path="/external-link" element={<External />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
reportWebVitals();
