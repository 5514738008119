import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./pages.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./pages.css";
import { useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
});

function Login() {
  const navigate = useNavigate();
  const [Site, setSite] = useState(null);
  const SiteFetch = async () => {
    try {
      const request = await axios.post("https://api.sharkpe.in/Sites", {
        host: window.location.host,
      });
      const response = await request.data;
      setSite(response?.data);
      localStorage.setItem("logo", response?.data?.logo);
    } catch (error) {
      toast.error("This Site Is Look Fraud ....");
    }
  };
  useEffect(() => {
    SiteFetch();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="login-wrapper">
        {Site && Site ? (
          <div className="align">
            <div className="d-flex">
              <img src={localStorage.getItem("logo")}></img>
              {/* <h1 className="fw-bolder">{Site?.site_name}</h1> */}
              {/* <h1>pe</h1> */}
            </div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                console.log(values);
                if (!values) {
                  console.log("empty string");
                  setSubmitting(false);
                } else {
                  await axios

                    .post("https://api.sharkpe.in/login/", values)
                    .then(function (response) {
                      setSubmitting(false);
                      resetForm({ values: { email: "", password: "" } });
                      console.log(response.data.message);
                      if (response.data.success) {
                        localStorage.setItem("token", response.data.token);
                        toast.success(response.data.message);
                        navigate("/dashboard");
                      } else {
                        resetForm({ values: { email: "", password: "" } });

                        toast.error("invalid credintial");
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      resetForm({ values: { email: "", password: "" } });

                      setSubmitting(false);
                      console.log(error.response.data.message);
                      toast.error(error.response.data.message);
                    });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setSubmitting,
                resetForm,
              }) => (
                <div className="login">
                  <div className="form mt-2">
                    <ToastContainer />
                    <form noValidate onSubmit={handleSubmit}>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Enter email id / username"
                        className="form-control inp_text"
                        id="email"
                      />

                      <p
                        className="error text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        {errors.email && touched.email && errors.email}
                      </p>

                      <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Enter password"
                        className="form-control"
                      />

                      <p
                        className="error text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        {errors.password && touched.password && errors.password}
                      </p>

                      <button
                        className="btn btn-success col-12 mt-4"
                        type="submit"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </Formik>

            <Link
              to="/forgetpassword"
              className="btn btn-forth text-dark col-12 mt-2 "
            >
              Forget Password
            </Link>

            <div className="col-12 mt-3">
              {" "}
              <div className="row">
                <div className="col-lg-6 col-sm-12 text-center pt-3">
                  {" "}
                  Dont have an account?
                </div>
                <Link
                  to="/signup"
                  className="btn btn-primary col-lg-5 col-sm-12 m-1"
                >
                  {" "}
                  sign up
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="sweet-loading">
           
            <ClockLoader color="#36d7b7" />

          </div>

        )}
      </div>
    </>
  );
}

export default Login;
